.bill-box {
    width: 100px;
    height: 100px;
    margin: 3px;
    /* background-color: aqua; */
}



.periodStatusClean {
    width: 100%;
    height: 3px;
    background-color: #157347;
}


.bill-status .dropdown-toggle, .bill-status .dropdown-menu, .bill-status .dropdown-item, .bill-status .dropdown-menu > li > a:hove {
    background-color: red;
    color: white;
  }
  
.bill-status .dropdown-item:hover {
background-color: red;
color: yellow;
}

.bill-status {
    padding: 0px;
    margin: 0px;
    background-color: red;
}